import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessagingService } from "./services/messaging.service";


import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  @ViewChild('audioOption') audioPlayerRef: ElementRef;
  message: any;
  showNotification: any = false;
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private messagingService: MessagingService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    const userId = '';
    this.messagingService.requestPermission(userId)
    
    // if(this.message.data){
    //   this.showNotification = true
    // }
    //console.log("this/.eadasdsa", this.message)
  }

}
