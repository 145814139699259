import { Component, ViewChild, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from '../../../services/main.service';
import { Api } from '../../../common/api';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { IProperty } from '../../../common/property';
import { Constants } from '../../../common/constants';
import { whiteSpaceValidator } from '../../../custom-validators.directive';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';


@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.scss']
})
export class EditAdminComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  keyword = 'name';
  imageLink: any;
  addressOptions: any = {};
  coordinates: any = [];
  timingData: any = [];
  startTime: any = [];
  endTime: any = [];
  active: any = [];
  editResForm: FormGroup;
  addressData: any;
  selectedAddress: any;
  submitted = false;
  public parameter: IProperty = {};
  adminId: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private mainService: MainService,
    private api: Api,
    private formBuilder: FormBuilder,
    public constants: Constants,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => {
      this.adminId = params['admin_id'];
    });
    //this.getParticularAdmin()
    //this.addFormValidator();
  }


  // getParticularAdmin(){
  //   this.mainService.getDataAptWithParams(this.api.paths.getAdminDetails, {}).subscribe(
  //     success => {
  //       //console.log("this.editResForm", this.editResForm)
  //     });
  // }

  // addFormValidator() {
  //   this.editResForm = this.formBuilder.group({
  //     name: ['', [Validators.required]],
  //   });
  // }

  // get lf() { return this.editResForm.controls; }
  
  // editAdmin() {
  //   //console.log("this.add", this.editResForm.value)
  //   this.submitted = true;
  //   // if (this.editResForm.invalid) {
  //   //   return;
  //   // }
  //   this.editResForm.value.timings = this.timingData;
  //   this.editResForm.value.coordinates = this.coordinates;
  //   this.mainService.postDataApi(this.api.paths.editAdmin, this.editResForm.value).subscribe(
  //     success => {
  //       this.toastrService.success('Admin updated successfully');
  //       this.router.navigate(['dashboard']);
  //     });
  // }

}
