export class Constants {
    public projectName = 'Food App';
    public itemsPerPage = 10;
    public page = 1;
    public countryCode = 'in';
    public dialCode = '91';
    public language = 'EN';
    public initialCountry = 'in';
    public fileSizeLimit = 20000000; // 20MB
   
    public recommendationType = {
        RECEIVED: 0,
        GIVEN: 1,
    };
    public messageType = {
        TEXT: 'TEXT',
        IMAGE: 'IMAGE',
        VIDEO: 'VIDEO',
    };
    public confirmButtonColor = '#006D6D';
    public cancelButtonColor = 'rgb(224, 73, 75)';
    public whiteSpaceRegex = '^\\s+$';
    public phonePattern = '^[0-9]{5,15}$';
    public emailPattern = '^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$';
    public urlPattern = 'http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+';
  
    public errorMsg: any = {
        COUNTRY_NAME_REQUIRED : 'Please enter country.'
    };
    public successMsg: any = {
        COUNTRY_ADDED_SUCCESSFULLY : 'Country added successfully',
    };
    public title: any = {
        ARE_YOU_SURE: 'Are you sure'
    };
}
