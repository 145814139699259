import { Injectable, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { MainService } from './../services/main.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { IProperty } from '../common/property';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  getDataAptWithParams(getRestaurant: string, input: { limit: number; skip: number; }) {
    throw new Error('Method not implemented.');
  }

  public parameter: IProperty = {};
  public loginSource = new BehaviorSubject<any>({});
  loginData$ = this.loginSource.asObservable();

  constructor(private mainService: MainService, private userService: UserService) {
    // this.getCurrentUserDetails();
    // this.update();
  }


  ngOnDestroy() {
    this.parameter.loginSub.unsubscribe();
  }

  // getCurrentUserDetails() {
  //   const accessToken = this.mainService.getAuthToken();
  //   this.parameter.loginSub = this.loginData$.subscribe(res => {
  //     // this.parameter.userData = res;
  //     if (accessToken && res._id === undefined) {
  //       this.userService.userDetails().subscribe(res1 => {
  //         this.parameter.userData = res1['data'].detail;
  //         if (this.parameter.userData) {
  //           this.loginSource.next(this.parameter.userData);
  //         }
  //       });
  //     }
  //   });
  // }
}
