<app-header
  [navbarBrandRouterLink]="['/users']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'assets/img/logo.webp',
    width: 100,
    height: 53,
    alt: 'Hoohaa'
  }"
  [navbarBrandMinimized]="{
    src: 'assets/img/logo.webp',
    width: 30,
    height: 30,
    alt: 'Hoohaa'
  }"
  [sidebarToggler]="'lg'"
  [mobileAsideMenuToggler]="false"
  [asideMenuToggler]="false"
>
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3">
      <a class="nav-link" href="/#/users">Users</a>
    </li>
    <!--  <li class="nav-item px-3">
      <a class="nav-link" href="#">Users</a>
    </li>
    <li class="nav-item px-3">
      <a class="nav-link" href="#">Settings</a>
    </li> -->
  </ul>

  <div
    class="scrolling-text"
    style="
      background-color: #003366;
      color: #ffffff;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      border-bottom: 2px solid;
    "
  >
    <marquee behavior="scroll" direction="left" scrollamount="10">
      Welcome to Our Portal! We need skilled professionals!
    </marquee>
  </div>

  <div
    class="scrolling-text"
    style="
      background-color: #003366;
      color: #ffffff;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 2px solid;
    "
  >
    <marquee behavior="scroll" direction="left" scrollamount="10">
      Open positions for Carpenters, Electricians, Drivers, and more.Check out
      our latest updates and announcements.
    </marquee>
  </div>

  <ul class="nav navbar-nav ml-auto">
    <!-- <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span></a>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-list"></i></a>
    </li>
    <li class="nav-item d-md-down-none">
      <a class="nav-link" href="#"><i class="icon-location-pin"></i></a>
    </li> -->
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <span class="navbar-toggler-icon"></span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <!-- <a class="dropdown-item" style="cursor: pointer;" (click)="editAdmin()"><i class="fa fa-user"></i>Edit</a> -->
        <a class="dropdown-item" style="cursor: pointer" (click)="logout()"
          ><i class="fa fa-lock"></i> Logout</a
        >
      </div>
    </li>
    ￼
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
  >
    <app-sidebar-nav
      [navItems]="navItems"
      [perfectScrollbar]
      [disabled]="appSidebar.minimized"
    ></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <!--  <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <span>Accept</span>
          <a class="btn">
            <label class="switch switch-sm switch-label switch-info" >
              <input type="checkbox" class="switch-input" [(ngModel)]='isChecked' (change)="changeStatus(isChecked)">
              <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
            </label>
          </a>
        </div>
      </li> -->
    </cui-breadcrumb>
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
    <!--<app-breadcrumb></app-breadcrumb>-->
    <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
    <!--<li class="breadcrumb-menu d-md-down-none">-->
    <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ol>-->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <!-- <span><a href="https://coreui.io">FoodApp</a> &copy; 2021.</span> -->
  <span class="ml-auto"
    >Crafted by
    <a href="https://kodekoast.com/" style="padding: 0px"
      ><img
        src="../assets/img/brand/kodekoast.svg"
        width="100"
        style="margin-bottom: 6px" /></a
  ></span>
</app-footer>
