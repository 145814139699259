import { Component } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Api } from '../../common/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { whiteSpaceValidator } from '../../custom-validators.directive';
import { UserRegister } from '../../models/user.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  loginForm: FormGroup;
  submitted = false;
  constructor(
    public mainService: MainService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public model: UserRegister,
    private spinner: NgxSpinnerService,
    private router: Router,
    private api: Api,
    ) 
    { 
      this.model = new UserRegister();
    }

  ngOnInit(): void {
    this.loginFormValidator();
  }

  loginFormValidator() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, whiteSpaceValidator()]],
      password: ['', [Validators.required, Validators.minLength(5), whiteSpaceValidator()]]
    });
  }

  get lf() { return this.loginForm.controls; }

  goLogin(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (!this.model.email.trim()) {
      this.toastrService.error('Please enter email.');
      return;
    }
    if (this.model.password.length !== this.model.password.trim().length) {
      this.toastrService.error('Please enter correct password.');
      return;
    }
    const input = {
      email: this.model.email,
      password: this.model.password
    };
    this.mainService.postDataApi(this.api.paths.login, input).subscribe(
      success => {
        localStorage.setItem('accessToken', success['data'].token);
        this.toastrService.success('Login successfully.');
        //this.updateFCM()
        this.router.navigate(['/users']);
      });
  }

  // updateFCM(){
  //   const fcmId = localStorage.getItem('fcm_token')
  //   const input = {
  //     fcmId: fcmId
  //   }

  //   this.mainService.postDataApi(this.api.paths.updateFCM, input).subscribe(
  //     success => {
  //       //console.log("success", success)
  //     });

  // }
 }
