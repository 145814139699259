import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MainService } from './main.service';
import { Constants } from '../common/constants';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    public mainService: MainService,
    private constants: Constants,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private authService: AuthService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('admin')) {
      this.spinner.show();
      console.log('----','adminnnnn' )
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.mainService.getAuthToken()}`,
        }
      });
    }else if(request.url.includes('findplacefromtext')) {
      request = request.clone({
        
      });
    } else {
       this.spinner.show();
      console.log('----',localStorage.getItem('restaurant_id') )
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.mainService.getAuthToken()}`,
        }
      });
    }
    
    return next.handle(request).pipe(
      tap(
        event => this.handleResponse(request, event),
        error => this.handleError(request, error)
      )
    );
  }

  handleResponse(req: HttpRequest<any>, event) {
    if (event instanceof HttpResponse) {
      this.spinner.hide();
      // console.log('-----Handling Response for----- ', req.url,
      //   ' Response Status ', event.status,
      //   ' With body ', event.body);
    }
  }

  handleError(req: HttpRequest<any>, event) {
    this.spinner.hide();
    if (event.status === 401) {
      // status code 401 means unauthorized
      this.toastrService.error('Token expired. Login again to continue.');
      localStorage.removeItem('accessToken');
        this.ngZone.run(() => {
          this.router.navigate(['login']);
        });
        this.authService.loginSource.next({});
    } else {
      this.toastrService.error(event.error.message);
    }
  }
}
