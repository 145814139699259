export class UserRegister {
    fullName: string;
    phoneNo: string;
    username: string;
    email: string;
    password: string;
    countryCode: string;
    dialCode: any;
    language: string;   // EN, AR, HD, CH, SP, TK
    deviceType: string;     // IOS, ANDROID, WEB
    deviceToken: string;
    experience: string;
    company: string;
    industry: string;
    education: any;
    socialMode: string;     // FACEBOOK, GOOGLE, TWITTER
    socialId: string;
    profilePic: string;
    image: File;
    otp: string;
    isEmailCmng: any; // boolean;
    profilePicURL: ProfilePicURL;
    currentAddress: string;
    currentLocation: Array<any>;
    isAgree: boolean;
}

export class ProfilePicURL {
    original: string;
    thumbnail: string;
}

export class UserProfile {
    _id: string;
    fullName: string;
    profilePicURL: any;
    phoneNo: string;
    countryCode: string;
    dialCode: string;
    language: string;   // EN, AR, HD, CH, SP, TK
    currentAddress: string;
    isCategorySelect: boolean;
    isLocationSharable: boolean;
    isProfessionSharable: boolean;
    socialId: string;
    socialMode: string;     // FACEBOOK, GOOGLE, TWITTER
    industry: string;
    experience: string;
    education: any;
    company: string;
    appColor: string;
    currentLocation: Array<any>;
    followers: number;
    following: number;
    email: string;
    deviceType: string;     // IOS, ANDROID, WEB
    deviceToken: string;
    hasFollowed: boolean;
}

export class FollwersList {
    userId: string;
    fullName: string;
    profilePicURL: ProfilePicURL;
    email: string;
    hasFollow: boolean;
}

export class UserSearchList {
    _id: string;
    fullName: string;
    profilePicURL: ProfilePicURL;
    email: string;
}

export class RecommendationList {
    _id: string;
    text: string;
    createdAt: any;
    users: RecommendedUser;
}

export class RecommendedUser {
    _id: string;
    fullName: string;
    profilePicURL: ProfilePicURL;
    currentAddress: string;
    currentLocation: Array<any>;
}

