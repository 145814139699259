import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
       _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  // updateToken(userId, token) {
  //   // we can change this function to request our backend service
  //   this.angularFireAuth.authState.pipe(take(1)).subscribe(
  //     () => {
  //       const data = {};
  //       data[userId] = token
  //       this.angularFireDB.object('fcmTokens/').update(data)
  //     })
  // }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem('fcm_token', token);
        console.log("token", token);
        //this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    console.log("receiveMessage::::Hook");
    // this.angularFireMessaging.messages.subscribe(
    //   (payload) => {
    //     console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);
    //   })
    
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data.messageType && event.data.messageType == 'push-received') {
        console.log("SW:::::::::push-received");
        this.currentMessage.next(event.data);
        this.currentMessage.next(null)
      }
      if (event.data.messageType && event.data.messageType == 'push-clicked') {
        console.log("SW:::::::::push-clicked");
        this.router.navigate(['/orders']);
        this.currentMessage.next(null)
      }
      if (event.data.messageType && event.data.messageType == 'push-received-background') {
        console.log("SW:::::::::push-received-background");
        this.currentMessage.next(event.data);
        this.currentMessage.next(null)
      }
    });
  }
}