<div class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <i class="fa fa-align-justify"></i>Users
          <!-- <button routerLink="/add-user" class="btn btn-sm btn-primary pull-right">Add User</button> -->
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name</th>
                <th>Email Details</th>
                <th>DOB</th>
                <th>Phone Number</th>
                <th>Passport Number</th>
                <th>Passport Expiry</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of modelCustomize
                    | paginate
                      : {
                          itemsPerPage: constants.itemsPerPage,
                          currentPage: parameter.page,
                          totalItems: parameter.total
                        };
                  let i = index
                "
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.fullName }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.dob || date }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.passportNumber }}</td>
                <td>{{ item.passportExpiry || date }}</td>
                <td>{{ item.fileStatus }}</td>

                <!-- <td *ngIf="item.permitValidity == 'VALID'">
                  <span class="badge badge-success">Valid</span>
                </td>
            
                <td *ngIf="item.isActive">
                  <label class="switch switch-sm switch-label switch-info">
                    <input
                      type="checkbox"
                      class="switch-input"
                      checked
                      (click)="changeStatus(item._id, false)"
                    />
                    <span
                      class="switch-slider"
                      data-checked="On"
                      data-unchecked="Off"
                    ></span>
                  </label>
                </td>
                <td *ngIf="!item.isActive">
                  <label class="switch switch-sm switch-label switch-info">
                    <input
                      type="checkbox"
                      class="switch-input"
                      (click)="changeStatus(item._id, true)"
                    />
                    <span
                      class="switch-slider"
                      data-checked="On"
                      data-unchecked="Off"
                    ></span>
                  </label>
                </td> -->
                <td>
                  <button
                    class="btn btn-sm btn-success"
                    routerLink="/edit-global-cat"
                    (click)="editItem(item._id)"
                  >
                    <i class="icon-pencil"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-danger"
                    (click)="copyMessage(item._id)"
                    value="click to copy"
                  >
                    <i class="icon-book-open"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #deleteModal>
            <div class="modal-body">
              <form class="text-center border border-light">
                <h5 class="h4 mb-4">User</h5>
                <p>Are you sure you want to delete?</p>
                <button
                  type="button"
                  (click)="modalRef.hide()"
                  class="btn btn-secondary"
                  style="margin-right: 10px"
                >
                  Close
                </button>
                <button
                  type="button"
                  (click)="delete(deleteId)"
                  class="btn btn-primary"
                >
                  Delete
                </button>
              </form>
            </div>
          </ng-template>
          <div class="pagination" *ngIf="model?.length != 0">
            <div class="col-6">
              <span
                >Showing {{ modelCustomize?.length }} of {{ parameter.total }}
                {{ modelCustomize?.length == 1 ? "User" : "Users" }}</span
              >
            </div>
            <div class="col-6 pull-right">
              <pagination-controls
                (pageChange)="getPage($event)"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
      <!--/.col-->
    </div>
  </div>
</div>
