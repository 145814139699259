import { NgModule } from '@angular/core';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { RouterOutlet, Routes, RouterModule } from '@angular/router';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { EditAdminComponent} from './views/admin/edit-admin/edit-admin.component';
import { UserComponent } from './views/users/user.component';
import { AddUserComponent } from "./views/users/add-user/add-user.component";
import { EditUserComponent } from "./views/users/edit-user/edit-user.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'edit-admin/:admin',
        component: EditAdminComponent
      },
       //*************User component ****************/
       {
        path: 'users',
        component: UserComponent,
        data: {
          title: 'User'
        }
      },
      {
        path: 'add-user',
        component: AddUserComponent,
        data: {
          title: 'Add User'
        }
      },
      {
        path: 'edit-user/:userId',
        component: EditUserComponent
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
