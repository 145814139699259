import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from './main.service';
import { environment } from './../../environments/environment';
import { Api } from './../common/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private api: Api, private mainService: MainService) { }

  // userDetails() {
  //   return this.http.get(environment.BASE_PATH + this.api.paths.getDetail);
  // }

}
