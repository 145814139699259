import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MainService } from "../../../services/main.service";
import { Api } from "../../../common/api";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { IProperty } from "../../../common/property";
import { Constants } from "../../../common/constants";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.scss"],
})
export class EditUserComponent implements OnInit {
  editUserForm: FormGroup;
  submitted = false;
  customizeData: any;
  public parameter: IProperty = {};
  userId: any;
  profilePicFile: File | null = null;
  imagesFiles: File[] = [];
  officialFiles: File[] = [];
  interviewFile: File | null = null;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private mainService: MainService,
    private api: Api,
    private formBuilder: FormBuilder,
    public constants: Constants,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.addFormValidator();
    this.route.params.subscribe((params) => {
      this.userId = params["userId"];
    });

    const data = {
      userId: this.userId,
    };

    this.mainService
      .getDataAptWithParams(this.api.paths.getUserDetails, data)
      .subscribe((success) => {
        this.customizeData = success["data"];
        this.editUserForm.patchValue(this.customizeData);
      });
  }

  addFormValidator() {
    this.editUserForm = this.formBuilder.group({
      fullName: [""],
      otherName: [""],
      gender: [""],
      dob: [""],
      fileSubmitDate: [""],
      profilePic: [""], // We'll store the file name here if needed
      placeOfBirth: [""],
      citizenship: [""],
      currentCountry: [""],
      countryApply: [""],
      maritalStatus: [""],
      spouseName: [""],
      nativeLanguage: [""],
      passportNumber: [""],
      passportCountry: [""],
      passportIssue: [""],
      passportExpiry: [""],
      nationalIdentity: [""],
      usPrCard: [""],
      visitFrom: [""],
      visitTo: [""],
      email: [""],
      phone: [""],
      altPhone: [""],
      address: [""],
      fax: [""],
      purposeVisit: [""],
      companyName: [""],
      workProfile: [""],
      salary: [""],
      finance: [""],
      fundsAvailable: [""],
      education: [""],
      tuberculosis: [""],
      medicalDisorder: [""],
      arrested: [""],
      political: [""],
      fileStatus: [""],
      images: [[]], // We'll store the file names or file data here if needed
    });
  }

  onFileChange(event: any, field: string) {
    if (field === "profilePic") {
      this.profilePicFile = event.target.files[0] || null;
    } else if (field === "images") {
      this.imagesFiles = Array.from(event.target.files);
    } else if (field == "official") {
      this.officialFiles = Array.from(event.target.files);
    } else if (field === "interview") {
      this.interviewFile = event.target.files[0] || null;
    }
  }

  editUser() {
    this.submitted = true;
    if (this.editUserForm.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append("userId", this.userId);

    // Add the profile pic if available
    if (this.profilePicFile) {
      formData.append("profilePic", this.profilePicFile);
    }

    // Add the interview file if available
    if (this.interviewFile) {
      formData.append("interview", this.interviewFile);
    }

    // Add the multiple images if available
    if (this.imagesFiles.length > 0) {
      this.imagesFiles.forEach((file, index) => {
        formData.append("images", file);
      });
    }

    if (this.officialFiles.length > 0) {
      this.officialFiles.forEach((file, index) => {
        formData.append("official", file);
      });
    }

    // Append other form data
    for (const key in this.editUserForm.value) {
      if (
        this.editUserForm.value.hasOwnProperty(key) &&
        key !== "profilePic" &&
        key !== "images"
      ) {
        formData.append(key, this.editUserForm.value[key]);
      }
    }

    this.mainService
      .postDataApi(this.api.paths.editUser, formData)
      .subscribe((success) => {
        this.toastrService.success("User updated successfully");
        this.router.navigate(["users"]);
      });
  }

  get lf() {
    return this.editUserForm.controls;
  }
}
