export class Api {
    public paths = {
        login: 'adminLogin',
        logout: 'logout',
        getAdminDetails: 'getParticularAdmin',
        createUser: 'createUser',
        editUser: 'updateUser',
        getUsers: 'getUsers',
        getUserDetails: 'getParticularUser',
        deleteUser: 'deleteUser'
    };
}
