import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
//import { Constants } from '../common/constants';

@Directive({
  selector: '[appCustomValidators]'
})
export class CustomValidatorsDirective {
  constructor() { }
}

export function ageRangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
      return { 'ageRange': true };
    }
    return null;
  };
}

export function whiteSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined && control.value.trim() === '') {
      return { 'whiteSpace': true };
    }
    // if (control.value !== undefined && control.value.match('^\\s+$')) {
    //   return { 'whiteSpace': true };
    // }
    return null;
  };
}
