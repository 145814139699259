import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { Api } from '../../common/api';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  isChecked: true;
  adminDetails: any;
  imageUrl: any;

  constructor(
    public mainService: MainService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private api: Api,
  ) {}

  ngOnInit(): void {
    this.getParticularAdmin();
  }

  getParticularAdmin(){
    this.mainService.getDataAptWithParams(this.api.paths.getAdminDetails, {}).subscribe(
      success => {
        this.adminDetails = success['data'];
        localStorage.setItem('admin_id', this.adminDetails._id)
        this.isChecked = success['data'].isAcceptingOrders;
      });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  changeStatus(checked){
    console.log(checked)
    const input = {
      status: checked
    }
    // this.mainService.postDataApi(this.api.paths.updateOrderAcceptanceStatus, input).subscribe(
    //   success => {
    //     this.toastrService.success(success['data'].data);
    //     this.getParticularAdmin();
    //   });
  }

  logout() {
    //this.mainService.postDataApi(this.api.paths.logout, {}).subscribe(
    //success => {
      this.router.navigate(['/login']);
      var fcm_token = localStorage.getItem('fcm_token');
      localStorage.clear();
      localStorage.setItem('fcm_token', fcm_token)
      this.toastrService.success('Logout Successfully');
    //});
  }

  editAdmin() {
    let adminId = localStorage.getItem('admin_id')
    this.router.navigate(['/edit-admin', adminId]);
  }
}
