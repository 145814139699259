import { TagInputModule } from 'ngx-chips';
import { NgModule,NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { ClipboardModule } from 'ngx-clipboard';


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor.service';
import { CustomValidatorsDirective } from './custom-validators.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ToastrModule} from 'ngx-toastr';
import { UserRegister } from './models/user.model';
import { Api } from './common/api';
import { MainService } from './services/main.service';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { Constants } from './common/constants';
import { NgxPaginationModule} from 'ngx-pagination';
import { EditAdminComponent } from './views/admin/edit-admin/edit-admin.component';
import { UserComponent } from './views/users/user.component';
import { AddUserComponent } from './views/users/add-user/add-user.component';
import { EditUserComponent } from './views/users/edit-user/edit-user.component';

@NgModule({
  imports: [
    TagInputModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    AutocompleteLibModule,
    NgSelectModule,
    GooglePlaceModule,
    ModalModule.forRoot(),
    FormsModule,     //Add if needed
    ReactiveFormsModule,//Add if needed 
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ClipboardModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  schemas: [ NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    CustomValidatorsDirective,
    EditAdminComponent,
    UserComponent,
    AddUserComponent,
    EditUserComponent,
  ],
  providers: [
    Constants,
    MessagingService,
    AsyncPipe,
    Api,
    MainService,
    AuthService,
    UserService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    [UserRegister,Api],
    IconSetService,
  
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
