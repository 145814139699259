<div class="animated fadeIn">
  <div class="row">
    <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <strong>Edit Restaurant</strong>
            <!-- <small>Form</small> -->
          </div>
          <form autocomplete="off" [formGroup]="editResForm">
            <div class="card-body">
             <!--  <div class="row" *ngIf="imageLink">
                <img class="circular--square" [src]="imageLink" />
              </div> -->
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Name">Name</label>
                  <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter your name">
                </div>
                <!-- <div class="form-group col-sm-6">
                  <label class="col-md-3 col-form-label" for="file-input">Image</label>
                  <div class="col-md-9">
                    <input autocomplete="off" id="imageUrl" accept="image/*" (change)="changeListner($event, 'img_loader', 'imageUrl')" placeholder="Image" type="file">
                  </div>
                </div> -->
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Email">Email</label>
                  <input type="text" class="form-control" formControlName="email"  id="email" placeholder="Enter your email">
                </div>
                <div class="form-group col-sm-2">
                  <label for="Country Code">Country Code</label>
                  <select class="form-control" formControlName="countryCode">
                    <option value="+61">+61</option>
                    <option value="+91">+91</option>
                  </select>
                </div>
                <div class="form-group col-sm-4">
                  <label for="Phone">Phone</label>
                  <input type="text" class="form-control" id="phone" formControlName="phone"placeholder="Enter your phone">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Landline">Landline</label>
                  <input type="text" class="form-control" id="landline" formControlName="landline" placeholder="Enter your Landline">
                </div>
                <!-- <div class="form-group col-sm-6">
                  <label for="Address">Address</label>
                    <input class="form-control" ngx-google-places-autocomplete formControlName="address" [options]="addressOptions" #placesRef="ngx-places" (onAddressChange)="selectEvent($event)"/>
                </div> -->
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Address">Address 2</label>
                     <input type="text" class="form-control" id="zipCode" formControlName="address2" placeholder="Shop No, Landmark">
                </div>
                <div class="form-group col-sm-6">
                  <label for="Zip Code">Zip Code</label>
                  <input type="text" class="form-control" id="zipCode" formControlName="zipCode" placeholder="Enter zip code">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="LicenseCode">License Code</label>
                  <input type="text" class="form-control" id="licenseCode" formControlName="licenseCode" placeholder="Enter license number">
                </div>
                <div class="form-group col-sm-6">
                  <label for="Delivery">Delivery Radius (in Km)</label>
                  <input type="text" class="form-control" id="deliveryRadius" formControlName="deliveryRadius" placeholder="Enter delivery radius (in Km)">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Average">Average Cost</label>
                  <input type="text" class="form-control" id="averageCost" formControlName="averageCost" placeholder="Enter average cost">
                </div>
                <div class="form-group col-sm-6">
                  <label for="Preparation">Average Preparation Time</label>
                  <input type="text" class="form-control" id="averagePreparationTime" formControlName="averagePreparationTime" placeholder="Enter preparation time">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                  <label for="Commission">Commission Rate (%)</label>
                  <input type="text" class="form-control" id="commissionRate" formControlName="commissionRate" placeholder="Enter commission rate (%)">
                </div>
                <div class="form-group col-sm-6">
                  <label for="MinumumOrderAmount">Minumum Order Amount</label>
                  <input type="text" class="form-control" id="minimumOrderAmount" formControlName="minimumOrderAmount" placeholder="Enter minimum order amount">
                </div>
              </div>
                <div class="form-group col-sm-6">
                  <label for="vat">Description</label>
                  <textarea id="textarea-input" name="textarea-input" id="description" formControlName="description" rows="4" class="form-control" placeholder="text here ...."></textarea>
                </div>
              
                <div class="form-group col-sm-8">
                  <label>Add Opening / Closing Timings</label>
                  <div class="col-form-label">
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-3">
                          <label>Active</label>
                        </div>
                        <div class="col-sm-3">
                          <label>Day</label>
                        </div>
                        <div class="col-sm-3">
                          <label>Start Time</label>
                        </div>
                        <div class="col-sm-3">
                          <label>End Time</label>
                        </div>
                      </div>
                      <!-- first row -->
                      <div class="row" *ngFor="let item of timingData; let i=index">
                        <div class="col-sm-3">
                          <label class="switch switch-sm switch-label switch-info" >
                            <input type="checkbox" class="switch-input" (change)="addTime(i)" [(ngModel)]="active[i]" [ngModelOptions]="{standalone: true}">
                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                          </label>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                              <input type="text" value="{{item.day}}" class="form-control" disabled>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <!-- <label for="Preparation">MM</label> -->
                            <input type="time" class="form-control" value="startTime[i]" (change)="addTime(i)" [(ngModel)]="startTime[i]" [ngModelOptions]="{standalone: true}">
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <!-- <label for="Commission">SS</label> -->
                            <input type="time" (change)="addTime(i)" value="endTime[i]" [(ngModel)]="endTime[i]" [ngModelOptions]="{standalone: true}" class="form-control" id="commissionRate" placeholder="SS">
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-lg btn-primary"><i class="fa fa-dot-circle-o"></i> Submit</button>
              <button type="reset" class="btn btn-lg btn-danger"><i class="fa fa-ban"></i> Reset</button>
            </div>
          </form>
        </div>
      </div><!--/.col-->
  </div><!--/.row-->
</div>
