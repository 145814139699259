import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from '../../../services/main.service';
import { Api } from '../../../common/api';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { IProperty } from '../../../common/property';
import { Constants } from '../../../common/constants';
import { whiteSpaceValidator } from '../../../custom-validators.directive';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  public parameter: IProperty = {};
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private mainService: MainService,
    private api: Api,
    private formBuilder: FormBuilder,
    public constants: Constants,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.addFormValidator();
  }

  addFormValidator() {
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      fwd: ['', [Validators.required]],
      fin: ['', [Validators.required]],
      workerFin: ['', [Validators.required]],
      permitValidity: ['', [Validators.required]],
      permitExpiry: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });
  }

 // get lf() { return this.userForm.controls; }
  
  addUser() {
    //console.log("this.userForm.value",this.userForm.value)
    this.submitted = true;
    // if (this.userForm.invalid) {
    //   return;
    // }

    console.log("this.addForm.value",this.userForm.value)
    this.mainService.postDataApi(this.api.paths.createUser, this.userForm.value).subscribe(
    success => {
      this.toastrService.success('user added successfully');
      this.router.navigate(['users']);
    });
  }

}
